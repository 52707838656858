import React, { Component } from 'react'
import Helmet from 'react-helmet'
import HeroBanner from '../Hero'
import SE0 from '../SEO'

class ExampleResidentialStyle1PageTemplate extends Component {
  render () {
    // console.log(this.props)

    return (
      <div>
        <Helmet>
          <title>{this.props.meta_title}</title>
          <meta name='description' content={this.props.meta_description} />
        </Helmet>
        <SE0
          title={this.props.title}
          meta_title={this.props.meta_title}
          meta_description={this.props.meta_description}
          date={this.props.date}
          cover={this.props.cover}
          slug={this.props.slug}
        />
        <HeroBanner
          title={this.props.title}
          subtitle={this.props.subtitle}
          hero_settings={this.props.hero_settings}
          cover={this.props.cover}
          cta_title={this.props.cta_title}
          cta_url={this.props.cta_url}
          cta_video_link={this.props.cta_video_link}
        />
        <section className='hero is-medium is-info is-bold is-light-primary'>
          <div className='hero-body'>
            <div className='container'>
              <p className='title'>{this.props.heading}</p>
              <div
                className='subtitle'
                dangerouslySetInnerHTML={{ __html: this.props.description }}
              />
            </div>
          </div>
        </section>
        <section className='hero is-fullheight is-light-primary'>
          <div className='hero-body is-paddingless iframe'>
            <iframe
              name={`aerialcontainer`}
              src={this.props.aerial}
              width='100%'
              title='Aerial 360 image'
              data-hj-allow-iframe
            />
          </div>
        </section>
      </div>
    )
  }
}

export default ExampleResidentialStyle1PageTemplate
