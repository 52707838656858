import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ExampleResidentialStyle1PageTemplate from '../components/ExamplePageTemplates/residential_style_1.js'

const ExampleResidentialStyle1Page = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <ExampleResidentialStyle1PageTemplate
      title={frontmatter.title}
      subtitle={frontmatter.subtitle}
      cover={frontmatter.cover}
      meta_title={frontmatter.meta_title}
      meta_description={frontmatter.meta_description}
      heading={frontmatter.heading}
      description={frontmatter.description}
      tour={frontmatter.tour}
      aerial={frontmatter.aerial}
      hero_settings={frontmatter.hero_settings}
      cta_title={frontmatter.cta_title}
      cta_url={frontmatter.cta_url}
      cta_video_link={frontmatter.cta_video_link}
    />
  )
}

ExampleResidentialStyle1Page.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ExampleResidentialStyle1Page

export const ExampleResidentialStyle1PageQuery = graphql`
  query ExampleResidentialStyle1Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        cover
        cta_title
        cta_url
        cta_video_link
        hero_settings {
          size
          particle
          images
          css
          font_color
          title_align
        }
        meta_title
        meta_description
        heading
        description
        tour
        aerial
      }
    }
  }
`
